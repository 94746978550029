import React from 'react';
import PropTypes from 'prop-types';


export default function Docs({ prev, next }) {
    return (
        <div className="p-6">
        </div>
    );
}

Docs.propTypes = {
    prev: PropTypes.shape({
        label: PropTypes.string,
        link: PropTypes.string,
    }),
    next: PropTypes.shape({
        label: PropTypes.string,
        link: PropTypes.string,
    }),
};

Docs.defaultProps = {
    prev: null,
    next: null,
};
